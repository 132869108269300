<!-- 用户导入弹窗 -->
<template>
  <a-modal
    :width="800"
    title="导出字段设置"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <template #footer>
      <a-space>
        <a-button type="info" @click="updateVisible(false)">取消</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </template>
    <a-form :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="字段:" name="fieldId">
        <a-checkbox-group
          style="width: 100%"
          v-model:value="form.fieldId"
          @change="fieldIdChange"
        >
          <a-row>
            <a-col :span="6" v-for="(item, index) in fieldList" :key="index">
              <a-checkbox :value="index">{{ item.name }}</a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as corpUserApi from '@/api/corp/corpUser.js'
import { toDateString } from '@/utils/util.js'
export default {
  name: 'CorpUserImport',
  emits: ['done', 'update:visible'],
  props: {
    // 是否打开弹窗
    visible: Boolean,
    where: Object,
    // 是否打开弹窗
    corpId: Number
  },
  data() {
    return {
      form: {
        fieldId: [0]
      },
      // 字段列表数据
      fieldList: [],
      // 导入请求状态
      loading: false
    }
  },
  mounted() {
    this.queryFormField()
  },
  watch: {
    corpId() {
      this.queryFormField()
    }
  },
  methods: {
    // 获取字段数据
    queryFormField() {
      const that = this
      that.fieldList = []
      that.form.fieldId = [0]
      corpUserApi
        .fieldList({ formId: 'corp-' + this.corpId, enable: true })
        .then((res) => {
          if (res.code === 0) {
            // debugger
            const arr = res.data
            that.fieldList = arr.map((item) => {
              return {
                field: item.fieldId,
                name: item.label,
                type: item.dateType
              }
            })
            that.fieldList.unshift({ field: 'state', name: '状态', type: '' })
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 导出字段选择改变
    fieldIdChange(e) {
      console.log('e', e)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 保存设置
    exportData() {
      const corpId = this.corpId
      const fieldList = []
      this.form.fieldId.forEach((item) => {
        fieldList.push(this.fieldList[item])
      })
      const params = {
        customFieldList: fieldList,
        where: this.where
      }
      corpUserApi
        .exportForm(corpId, params)
        .then((res) => {
          const { data, headers } = res
          const fileName =
            '成员信息-' + toDateString(new Date(), 'MMddHHmmss') + '.xlsx' // headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
          // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
          // const blob = new Blob([JSON.stringify(data)], ...)
          const blob = new Blob([data], { type: headers['content-type'] })
          const dom = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          dom.href = url
          dom.download = decodeURI(fileName)
          dom.style.display = 'none'
          document.body.appendChild(dom)
          dom.click()
          dom.parentNode.removeChild(dom)
          window.URL.revokeObjectURL(url)
          this.updateVisible(false)
        })
        .catch((e) => {})
    }
  }
}
</script>

<style scoped>
</style>
